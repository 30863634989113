<template>
  <div class="result page-container">
    <PageTitle :title="currentResult.title" />
    <BaseIcon class="result_icon" :name="currentResult.icon" />
    <template v-if="resultCode !== '6'">
      <h3 class="result_content_title mb-1">
        {{ currentResult.contentTitle }}
      </h3>
      <p
        class="result_content_detail mb-8"
        v-html="currentResult.contentDetail"
      ></p>
      <!-- btn -->
      <BaseBtn text="查詢我的發票" @click="routeTo('/member')" />
      <BaseBtn
        text="回到首頁"
        type="back"
        :isOutlined="true"
        @click="routeTo('/home')"
      />
    </template>
    <template v-else>
      <p class="result_content_detail mb-4">
        可能的原因有以下導致辨識失敗<br />
        請重新拍攝或改以傳統發票上傳登錄
      </p>
      <ol class="mb-8">
        <li>拍攝角度不正</li>
        <li>拍攝光源不足</li>
        <li>發票紙本皺褶</li>
        <li>發票文字不清</li>
      </ol>
      <!-- btn -->
      <BaseBtn text="重新拍照上傳" @click="routeTo('/electricInvoicePhoto')" />
      <BaseBtn
        text="手動輸入發票資訊"
        type="back"
        :isOutlined="true"
        @click="routeTo('/electricInvoiceHand')"
      />
      <BaseBtn
        text="重新選擇登錄發票類型"
        type="back"
        :isOutlined="true"
        @click="routeTo('/invoiceLog')"
      />
    </template>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import BaseBtn from "@/components/BaseBtn.vue";
import BaseIcon from "@/components/BaseIcon.vue";

export default {
  name: "Result",
  components: {
    PageTitle,
    BaseBtn,
    BaseIcon,
  },
  data() {
    return {
      resultList: {
        0: {
          title: "發票登錄成功",
          icon: "check",
          contentTitle: "審核成功",
          contentDetail: "恭喜完成登錄發票<br />你已獲得抽獎機會",
        },
        1: {
          title: "發票登錄待審核",
          icon: "search",
          contentTitle: "發票待系統/人工審核",
          contentDetail:
            "你已成功登錄發票<br />待財政部系統或人工審核通過<br />系統審核至多48小時，人工審核至多7天<br />謝謝您的耐心等候",
        },
        2: {
          title: "發票登錄失敗",
          icon: "cross",
          contentTitle: "發票號碼重複登錄",
          contentDetail:
            "此發票已登錄過<br />故無法再登錄此發票<br />請至「點數查詢」頁面確認已登錄的發票狀態",
        },
        3: {
          title: "發票登錄失敗",
          icon: "cross",
          contentTitle: "未購買活動品項",
          contentDetail:
            "您所登錄的發票未包含活動品項<br />請再次確認是否包含此活動品項",
        },
        4: {
          title: "發票登錄失敗",
          icon: "cross",
          contentTitle: "發票開立時間不符",
          contentDetail:
            "您所登錄的發票開立時間不符合活動期間<br />活動期間:<br />詳情請參閱活動辦法",
        },
        5: {
          title: "發票登錄失敗",
          icon: "cross",
          contentTitle: "登錄資訊錯誤",
          contentDetail:
            "您所登錄的發票資訊錯誤，<br />請輸入正確發票號碼、隨機碼、發票日期",
        },
        6: {
          title: "照片辨識失敗",
          icon: "cross",
        },
      },
    };
  },
  computed: {
    resultCode() {
      return this.$route.query.result || 0; //預設待定
    },
    currentResult() {
      return this.resultList[this.resultCode];
    },
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss">
.result {
  &_icon {
    margin: 15px 0 32px;
  }
  &_content {
    &_title {
      font-weight: 500;
    }
  }
}
</style>